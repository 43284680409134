<template>
    <div>
        <app-page-header></app-page-header>
        <v-container grid-list-xl>
            <!-- kriterij iskanja -->
            <v-layout row wrap align-left justify-space-around fill-height>
                <v-flex xs12 sm12 md12 lg12 xl12>
                    <v-card>
                            <v-card-title>
                                    <span class="subtitle-1"><v-icon left>mdi-filter</v-icon> Kriterij iskanja</span>
                                    <v-spacer></v-spacer>
                                    <v-btn icon @click="toggleCardContent('card1')">
                                        <v-icon>{{ card1 ? 'mdi-chevron-down' : 'mdi-chevron-up' }}</v-icon>
                                    </v-btn>
                            </v-card-title>
                            <v-divider v-show="card1"></v-divider>
                            <v-card-text v-show="card1">
                                <!-- tip uporabnika-->
                                <v-container grid-list-xl text-x-center align-center  class="container--filters">
                                    <v-layout row wrap justify-center v-bind="columnBinding">
                                        <v-flex xs12 md4 align-content-center>
                                            <v-subheader class="pt-7 pl-0"><strong>Tip uporabnika</strong></v-subheader>
                                        </v-flex>
                                        <v-flex xs12 md8 align-content-center>
                                            <v-select
                                            v-model="selected_user_types"
                                            :items="userTypes"
                                            label="Izberi tip uporabnika"
                                            multiple
                                            chips
                                            clearable
                                            deletable-chips
                                            prepend-icon="mdi-monitor-star"
                                            item-value="id"
                                            item-text="name"
                                            return-object
                                            dense
                                            no-data-text="Ni podatkov za prikaz"
                                        ></v-select>   
                                        </v-flex>
                                    </v-layout>
                                </v-container>

                                <!-- tipi omejitev -->
                                <v-container grid-list-xl text-x-center align-center  class="container--filters">
                                    <v-layout row justify-center v-bind="columnBinding">
                                        <v-flex xs12 md4 align-content-center>
                                            <v-subheader class="pt-7 pl-0"><strong>Tip omejitve</strong></v-subheader>
                                        </v-flex>
                                        <v-flex xs12 md8 align-content-center>
                                            <v-select
                                            v-model="selected_restriction_types"
                                            :items="restrictionTypes"
                                            label="Izberi tip omejitve"
                                            multiple
                                            chips
                                            clearable
                                            deletable-chips
                                            prepend-icon="mdi-monitor-star"
                                            item-value="id"
                                            item-text="name"
                                            return-object
                                            dense
                                            no-data-text="Ni podatkov za prikaz"
                                        ></v-select>   
                                        </v-flex>
                                    </v-layout>
                                </v-container>

                                <!--
                                <v-container grid-list-xl text-x-center align-center class="container--filters">
                                    <v-layout row justify-center v-bind="columnBinding">
                                        <v-flex xs12 md4 align-content-center>
                                            <v-subheader class="pt-7 pl-0"><strong>Podjetje</strong></v-subheader>
                                        </v-flex>
                                        <v-flex xs12 md8 align-content-center>
                                            <v-select
                                            v-model="selected_companies"
                                            :items="companies"
                                            label="Izberi podjetje"
                                            multiple
                                            chips
                                            clearable
                                            deletable-chips
                                            prepend-icon="mdi-monitor-star"
                                            item-value="id"
                                            item-text="name"
                                            return-object
                                            dense
                                            no-data-text="Ni podatkov za prikaz"
                                        ></v-select>   
                                        </v-flex>
                                    </v-layout>
                                </v-container>
                                -->
                                

                                <!-- id kartice -->
                                <!--<v-container grid-list-xl text-x-center align-center  class="container--filters">
                                    <v-layout row justify-center v-bind="columnBinding">
                                        <v-flex xs12 md4 align-content-center>
                                            <v-subheader class="pt-7 pl-0"><strong>ID kartice</strong></v-subheader>
                                        </v-flex>
                                        <v-flex xs12 md8 align-content-center>
                                            <v-text-field
                                            v-model="card_id"
                                            label="Vpiši ID kartice"
                                            prepend-icon="mdi-pencil"
                                            @keyup.esc="clearCardId"
                                            @keyup.enter="search"
                                            clearable
                                            ></v-text-field>
                                        </v-flex>
                                    </v-layout>
                                </v-container>-->

                                <!-- Registrska številka -->
                                <v-container grid-list-xl text-x-center align-center class="container--filters">
                                    <v-layout row justify-center v-bind="columnBinding">
                                        <v-flex xs12 md4 align-content-center>
                                            <v-subheader class="pt-7 pl-0"><strong>Registrska številka</strong></v-subheader>
                                        </v-flex>
                                        <v-flex xs12 md8 align-content-center>
                                            <v-text-field
                                            v-model="registration_number"
                                            label="Vpiši registrsko številko"
                                            prepend-icon="mdi-pencil"
                                            @keyup.esc="clearRegistrationNumber"
                                            @keyup.enter="search"
                                            clearable
                                            ></v-text-field>
                                        </v-flex>
                                    </v-layout>
                                </v-container>

                                <!-- telefonska številka -->
                                <!--<v-container grid-list-xl text-x-center align-center class="container--filters">
                                    <v-layout row justify-center v-bind="columnBinding">
                                        <v-flex xs12 md4 align-content-center>
                                            <v-subheader class="pt-7 pl-0"><strong>Telefonska številka</strong></v-subheader>
                                        </v-flex>
                                        <v-flex xs12 md8 align-content-center>
                                            <v-text-field
                                            v-model="phone_number"
                                            label="Vpiši telefonsko številko"
                                            prepend-icon="mdi-pencil"
                                            @keyup.esc="clearPhoneNumber"
                                            @keyup.enter="search"
                                            clearable
                                            ></v-text-field>
                                        </v-flex>
                                    </v-layout>
                                </v-container>-->

                                <v-container grid-list-xl text-x-center align-center  class="container--filters">
                                    <v-layout row justify-center v-bind="columnBinding">
                                        <v-flex xs12 md4 align-content-center>
                                            <v-subheader class="pt-6 pl-0"><strong>Pretečena veljavnost</strong></v-subheader>
                                        </v-flex>
                                        <v-flex xs12 md8 align-content-center>
                                            <v-checkbox @change="displayResults()" class="ti" v-model="expired" :label="`Prikaži samo pretečene uporabnike: ${checkboxExpiredUsersOnlyLabel}`"></v-checkbox>
                                        </v-flex>
                                    </v-layout>
                                </v-container>

                                <!--<v-container grid-list-xl text-x-center align-center  class="container--filters">
                                    <v-layout row justify-center v-bind="columnBinding">
                                        <v-flex xs12 md4 align-content-center>
                                            <v-subheader class="pt-6 pl-0"><strong>Blokirane kartice</strong></v-subheader>
                                        </v-flex>
                                        <v-flex xs12 md8 align-content-center>
                                            <v-checkbox @change="displayResults" class="ti" v-model="blocked_users" :label="`Prikaži samo blokirane kartice: ${checkboxBlockedCardsOnlyLabel}`"></v-checkbox>
                                        </v-flex>
                                    </v-layout>
                                </v-container>-->

                                <!--
                                <v-container grid-list-xl text-x-center align-center  class="container--filters">
                                    <v-layout row justify-center v-bind="columnBinding">
                                        <v-flex xs12 md4 align-content-center>
                                            <v-subheader class="pt-6 pl-0"><strong>Aktivni uporabniki</strong></v-subheader>
                                        </v-flex>
                                        <v-flex xs12 md8 align-content-center>
                                            <v-checkbox @change="displayResults" class="ti" v-model="active_users" :label="`Prikaži samo uporabnike na parkirišču: ${checkboxActiveUsersLabel}`"></v-checkbox>
                                        </v-flex>
                                    </v-layout>
                                </v-container>
                                -->

                                <!--<v-container grid-list-xl text-x-center align-center  class="container--filters">
                                    <v-layout row justify-center v-bind="columnBinding">
                                        <v-flex xs12 md4 align-content-center>
                                            <v-subheader class="pt-6 pl-0"><strong>Skeniraj kartico</strong></v-subheader>
                                        </v-flex>
                                        <v-flex xs12 md8 align-content-center>
                                            <v-btn v-if="$vuetify.breakpoint.smAndDown" class="mt-3" @click="clickToScan" v-show="!displayScanNrInput" color="warning" block dark><v-icon small left>mdi-wifi</v-icon>Klikni tukaj za skeniranje</v-btn>
                                            <v-btn v-else class="mt-3" @click="clickToScan" v-show="!displayScanNrInput" color="warning" dark small><v-icon small left>mdi-wifi</v-icon>Klikni tukaj za skeniranje</v-btn>
                                            <v-form ref="form"
                                            v-model="form"
                                            append>
                                                <v-text-field
                                                    v-model="scan_nr"
                                                    label="Prisloni kartico na optični čitalnik...."
                                                    v-show="displayScanNrInput"
                                                    ref="scan_input"
                                                    @keydown.enter.prevent="scanCard"
                                                    id="scanInputField"
                                                    class="ml-4 cb"
                                                ></v-text-field>
                                                
                                            </v-form>
                                        </v-flex>
                                    </v-layout>
                                </v-container>-->
                                

                            </v-card-text>
                            <v-divider v-if="card1">
                            </v-divider>
                            <span v-if="card1">
                                <v-card-text v-if="$vuetify.breakpoint.xsOnly">
                                <v-btn   @click="search()" color="secondary" dark  block outlined style=""><v-icon left>mdi-magnify</v-icon>Prikaz rezultatov&nbsp;</v-btn><br>
                                <v-btn v-if="checkApplicationPermissions(['zaton-export-users', 'zaton-data-export'])"    @click="exportData()" color="secondary" dark  block outlined style=""><v-icon left>mdi-export</v-icon>Izvoz&nbsp;</v-btn>
                                </v-card-text>
                                <v-card-text v-else>
                                    <v-btn  @click="search()" color="secondary" dark text><v-icon left>mdi-magnify</v-icon>Prikaz rezultatov&nbsp;</v-btn>
                                    <v-btn v-if="checkApplicationPermissions(['zaton-export-users', 'zaton-data-export'])"   @click="exportData()" color="secondary" dark text><v-icon left>mdi-export</v-icon>Izvoz&nbsp;</v-btn>
                                </v-card-text>
                            </span>
                    </v-card>
                </v-flex>
            </v-layout>
            <br>
            <users-data-table
            :settings="dataTableSettings"
            :queryParameters="queryParameters"
            ></users-data-table> 

            <data-table-toggle-columns
            :dataTableId="dataTableSettings.id"
            :columns="dataTableSettings.headers"
            :dataTableColumnsMenuId="dataTableSettings.eventBus.toggleColumnsId"

            ></data-table-toggle-columns>
        </v-container>
    </div>
</template>

<script>
import { EventBus } from '@/main.js'
import { getCurrentDate, getCurrentTime, formatDate, isMobileDevice, getUserApplicationPermissions, checkPermissions, getAppSettingsFromLocalStorage } from '@/helpers/utilities'
const UsersDataTable = () => import('@/components/UsersDataTable.vue')
const DataTableToggleColumns = () => import('@/components/DataTableToggleColumns.vue')
import { addExportToQueue } from '@/helpers/services.js'

export default {
    components: {
        UsersDataTable,
        DataTableToggleColumns
    },

    data: () => ({
        card1: true,
        card2: true,
        form: true,
        phone_number: null,
        registration_number: null,
        selected_user_types: [],
        selected_restriction_types: [],
        selected_companies: [],

        userTypes: [],
        restrictionTypes: [],
        companies: [],
        card_id: null,
        blocked_users: false,
        scan_nr: null,
        scan_btn: true,
        expired: false,
        active_users: false,

        dataTableSettings: {
            id: 'dtUsers',
            title: 'Seznam uporabnikov',
            sortColumn: ['id'],
            sortDirection: ['desc'],
            progressHeight: 4,
            headers: [
                { text: "ID", value: "id", class: "text-xs-left", visibility: true},
                { text: "Naziv", value: "name", class: "text-xs-left", visibility: true},
                //{ text: "Podjetje", value: "company", class: "text-xs-left", visibility: true},
                //{ text: "Naslov", value: "address", class: "text-xs-left", visibility: true},
                { text: "Tip uporabnika", value: "user_type.name", class: "text-xs-left", visibility: true },
                //{ text: "ID kartice", value: "card_id", class: "text-xs-left", visibility: true },
               //{ text: "Št. kartice", value: "card_number", class: "text-xs-left", visibility: true },
                //{ text: "Telefonska št.", value: "phone_number", class: "text-xs-left", visibility: true },
                { text: "Registrska številka", value: "registration_numbers", class: "text-xs-left", visibility: true, sortable: false },
                { text: "Veljavnost od", value: "restriction_start_date_formatted", class: "text-xs-left", visibility: true, sortable:true },
                { text: "Veljavnost do", value: "restriction_end_date_formatted", class: "text-xs-left", visibility: true, sortable:true },
                { text: "Upravljanje", value: "action", align: "right", class: "text-xs-right", sortable: false, visibility: true},
            ],
            updateInterval: true,
            rowsPerPage: 10,
            endpoint: 'zaton-krsko/rfid-users',
            editItem: {
                route: 'edit_user',
                routeParameterValue: 'uuid'
            },
            deleteItem: {
                vuexAction: 'DELETE_RFID_USER',
                replaceStrWithColumnValue: 'name',
                confirmationText : "Sta prepričani da želite odstraniti uporabnika {str_to_replace} ?",
                dialogConfirmSuccessText: 'Uporabnik {str_to_replace} je bil uspešno odstranjen.',
                dialogConfirmFailText: 'Pri odstranjevanju uporabnika {str_to_replace} je prišlo do napake. Poskusite ponovno.'
            },

            toolbar: {
                buttons: {
                    refresh: true,
                    export: false,
                    addNew: true,
                    addNewRoute: 'add_user',
                    toggleColumns: true
                }
            },

            eventBus: {
                refreshDataTable: 'refresh_dt_rfid_users',
                totalItems : {
                    event: ''
                },
                search: 'dt-rfid-users-search',
                toggleColumnsId: 'toggle-rfid-users-list-columns'
            }


        }
    }),

    computed: {
        columnBinding() {
            const binding = {}

            if (this.$vuetify.breakpoint.mdAndDown) binding.column = true

            return binding
        },

        queryParameters() {
            return {
                cardId: this.card_id,
                registrationNumber: this.registration_number,
                phoneNumber: this.phoneNumber,
                selectedUserTypes: this.selected_user_types.map(item => item.uuid),
                selectedRestrictionTypes: this.selected_restriction_types.map(item => item.id),
                blockedCards: this.blocked_users,
                expired: this.expired,
                activeUsers: this.active_users,
                selectedCompanies: this.selected_companies.map(item => item.id),
            }
        },

        checkboxBlockedCardsOnlyLabel() {
            if(this.blocked_users) {
                return "DA"
            }

            return "NE"
        },

        checkboxExpiredUsersOnlyLabel() {
            if(this.expired) {
                return 'DA'
            }

            return 'NE'
        },

        checkboxActiveUsersLabel() {
            if(this.active_users) {
                return 'DA'
            }

            return'NE'
        },

        displayScanNrInput() {
            if(!this.scan_btn) {
                return true
            }

            return false
        }
    },

    watch: {
        card1: {
            handler(value) {
                //window.console.log("Telemetry card1: " + value)
                this.$store.commit("APP_SETTING", {
                    setting: "users",
                    key: "filter_results_open",
                    value: value
                })
            },

            //immediate: true
        }
    },

    methods: {
        checkApplicationPermissions(requiredPermissions) {
            return checkPermissions(requiredPermissions, getUserApplicationPermissions())
        },

        toggleCardContent(card) {
            if(card === 'card1') {
                this.card1 = !this.card1
            }
        },

        search() {
            setTimeout(() => {
                EventBus.$emit(this.dataTableSettings.eventBus.refreshDataTable, 'pass-some-data');
            }, 250)
        },

        clearPhoneNumber() {
            this.phone_number = null
        },

        clearRegistrationNumber() {
            this.registration_number = null
        },

        clearCardId() {
            this.card_id = null
        },

        displayResults() {
            setTimeout(() => {
                EventBus.$emit(this.dataTableSettings.eventBus.refreshDataTable, 'pass-some-data');
            }, 250)
        },

        clickToScan() {
            this.scan_btn = false
            this.setFocus()
            var vm = this
        },

        setFocus() {
            if(!this.scan_btn) {
                var vm = this
                setTimeout(() => {
                    vm.$refs.scan_input.focus()
                }, 250)
            }
        },

        scanCard() {
            var vm = this
            vm.$store.commit('SET_PROGRESS', true)
            this.$store.dispatch('RFID_NR_SEARCH', this.scan_nr)
            .then(response => {
                //console.log(response)
                //console.log(response.data.rfidNr)
                EventBus.$emit(this.dataTableSettings.eventBus.search, response.data.rfidNr.toString());
                this.scan_nr = null
                this.scan_btn = true
                vm.$store.commit('SET_PROGRESS', false)
                setTimeout(() => {
                    vm.search()
                }, 250)
            })
            .catch(error => {
                this.scan_nr = null
                this.scan_btn = true
                vm.$store.commit('SET_PROGRESS', false)
                vm.$store.commit('snackbarDisplay', true)
                vm.$store.commit('snackbarMode', 'multi-line')
                vm.$store.commit('snackbarText', 'Pri skeniranju kartice je prišlo do napake. Prosimo poskusite ponovno.')
                vm.$store.commit('snackbarDisplay', true)
            })
        },

        exportData() {
            window.console.log("exporting data ...");
            let parameters = {
                cardId: this.card_id,
                registrationNumber: this.registration_number,
                phoneNumber: this.phoneNumber,
                selectedUserTypes: this.selected_user_types.map(item => item.uuid),
                selectedRestrictionTypes: this.selected_restriction_types.map(item => item.id),
                blockedCards: this.blocked_users,
                expired: this.expired,
                activeUsers: this.active_users,
                selectedCompanies: this.selected_companies.map(item => item.id),
            }
            
            addExportToQueue('users', parameters);
        },
    },

    created() {
        this.$store.dispatch('ROUTE_NAME', this.$route.name) 

        this.card1 = this.$store.getters.appSettings.users.filter_results_open

        let ipp = getAppSettingsFromLocalStorage({
            setting: "dt_users",
            key: "items_per_page"
        })

        if(ipp !== null) {
            this.dataTableSettings.rowsPerPage = ipp
        }

        /*
        if(isMobileDevice(this)) {
            this.card1 = false
        }
        */
    },

    mounted() {
        var vm = this
        vm.$store.commit('SET_PROGRESS', true)
        vm.$store.dispatch('PARAMETERS_DATA')
        .then(response => {
            //window.console.log(response.data)

            response.data.restrictionTypes.forEach(restrictionType => {
                vm.restrictionTypes.push(restrictionType)
            })

            
            response.data.companies.forEach(company => {
                vm.companies.push(company)
            })
            

            vm.userTypes = response.data.userTypes
        })
        .catch(error => {
            window.console.error("### ERROR Users.vue@mounted ###");
            window.console.error(error)
        })
        .finally(() => {
            vm.$store.commit('SET_PROGRESS', false)
        })

        /*document.getElementById("scanInputField").addEventListener("blur", function(){
            vm.scan_btn = true
        });*/
    },

    destroyed() {
        EventBus.$off(this.dataTableSettings.id)
    }
}

</script>

<style scoped>

</style>